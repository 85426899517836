<template>
  <div id="all" style="min-height:100%">
    <div style="width: 100%;overflow: hidden;" id="address">
    <!-- <img src="../assets/loveImage.gif" id="welcome_icon"> -->
      <div class="" id="box" style="float:left;width: 80%;float: right;" >
        <ul>
          <li v-for="v in this.result" :key="v.value" id="liId" 
           >
           <!-- @dblclick="dbSmall(v.imageName)" -->
            <!-- @cancel="handleCancel --> 
            <img :visible="visible" :footer="null"  class="box2"
            :id="v.imageName"
            @mouseleave="toSmallChange(v.imageName)" 
            @click="toBIgChange(v.imageName)"
            :src="v.imageName"
            alt="" 
            :style="{transform:'scale('+multiples+')'}">
            <p style="font-size: small;color: #666;margin: 0px;" >{{v.imageAddress}}
              <a id="hert" @click="userMessagePinLun">❤</a>
              <!-- <input type="button" value="❤" style="border:none" > -->
            </p>
            
          </li>
        </ul>
        
        <div id="page">
          <input type="button" value="上一页" @click="prePage">
          <span  v-for="i in this.show" @click="page(i)" :style="{cursor: 'pointer', margin:'10px'}" :key="i">{{ i }}</span>
          <input type="button" :value="this.pageMore" @click="nextPage">
        </div>
      </div>
      
    
	</div>

  <div class="about">
    <div style="float:right;width: 28%;float: left;" class="mytree" id="tree">
          <el-tree style="float:left;width: 65%;float: left;"
           class="el-tree-node__label"
          
          :data="treeOrder"
          :props="defaultProps"
          accordion
          @node-click="handleNodeClick"
          >
       
      </el-tree>
    </div>
  </div>
  
  </div>
  
<div>


   
    <!-- <h1>This is an about page</h1> -->
    <!-- <input type="file" ref="fileInput" @change="onchangemd" accept=".jpg,.png"> -->
    
  <!-- </div> -->

</div>
<div class="mask" v-if="showModal" @click="showModal=false"></div>
    <div class="pop" v-if="showModal">
        <!-- <button @click="showModal=false" class="btn">点击出现弹框</button> -->
         <img 
         class="image"
        @mouseleave="toSmallChange('1')"
        :src="this.imageUrl" 
        id="1"
        >
    </div>

</template>


<script>
  
	


import axios from 'axios'
// import resultPublic from '../store/public.js'

 
export default {
  name: 'Love_Image',
  data() {
      return { 
        showModal: false,
        imageUrl:'',
        number:0,
        visible: false,
        multiples: 1,
        pageId:0,
        start:1 ,
        show:[1,2,3,4],
        pageMore:'下一页',
        addressId:0,
        pageNumId:1,
        pageNum: 1,       
        imgIcon:'./assets/tourism.jpg',
        treeOrder:[],
        treeData: [
          {
            addressId:'',
            addressName:''
          }
        ],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        result:{
          imageId:'',
          imageName:'',
		imageAddress:''
        }
      }
  },
  
  props: {
    msg: String
  },

  created() {
    this.getLoveList();
    this.getAddressList();
  },
  methods:{
   
      // 下一页 
      nextPage() {
        if (this.pageNumId === Math.ceil(this.pageNum/4)) return ;
        if(this.addressId===0){
            this.pageNumId=this.pageNumId+1 
            this.pageMore="("+this.pageNumId+"/"+Math.ceil(this.pageNum/4)+")下一页"
            this.start=this.start+4
            axios.post("/api/love/loveList?pageNum="+this.start+"&pageSize="+6).then(response=>{
            this.result=response.data.list;
            if(this.pageNumId*4<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1,this.pageNumId*4]
              console.log(this.show)
            }else if(this.pageNumId*4-1<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1]
            }else if(this.pageNumId*4-2<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2]
            }else if(this.pageNumId*4-3<=response.data.pages){
              this.show=[this.pageNumId*4-3]
            }
            })
        }else{
             this.pageNumId=this.pageNumId+1
             this.pageMore="("+this.pageNumId+"/"+Math.ceil(this.pageNum/4)+")下一页"
             this.start=this.start+4
             axios.post("/api/love/loveListByAddressId?addressId="+this.addressId+"&pageNum="+this.start+"&pageSize=6").then(response=>{
              this.result=response.data.list;
              this.pageNum=response.data.pages;
               if(this.pageNumId*4<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1,this.pageNumId*4]
              console.log(this.show)
            }else if(this.pageNumId*4-1<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1]
            }else if(this.pageNumId*4-2<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2]
            }else if(this.pageNumId*4-3<=response.data.pages){
              this.show=[this.pageNumId*4-3]
            }
            })
        }
       
      },
      // 上一页
      prePage() {
        if (this.pageNumId === 1) return ;
        if(this.addressId===0){
              this.pageNumId=this.pageNumId-1
              this.pageMore="("+this.pageNumId+"/"+Math.ceil(this.pageNum/4)+")下一页"
              this.start=this.start-4
              axios.post("/api/love/loveList?pageNum="+this.start+"&pageSize="+6).then(response=>{
              this.result=response.data.list; 
              this.pageNum=response.data.pages
            if(this.pageNumId*4<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1,this.pageNumId*4]
            }else if(this.pageNumId*4-1<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1]
            }else if(this.pageNumId*4-2<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2]
            }else if(this.pageNumId*4-3<=response.data.pages){
              this.show=[this.pageNumId*4-3]
            }
            })
        }else{
             this.pageNumId=this.pageNumId-1
             this.pageMore="("+this.pageNumId+"/"+Math.ceil(this.pageNum/4)+")下一页"
             this.start=this.start-4
             axios.post("/api/love/loveListByAddressId?addressId="+this.addressId+"&pageNum="+this.start+"&pageSize=6").then(response=>{
              this.result=response.data.list;
              this.pageNum=response.data.pages;
            if(this.pageNumId*4<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1,this.pageNumId*4]
            }else if(this.pageNumId*4-1<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2,this.pageNumId*4-1]
            }else if(this.pageNumId*4-2<=response.data.pages){
              this.show=[this.pageNumId*4-3,this.pageNumId*4-2]
            }else if(this.pageNumId*4-3<=response.data.pages){
              this.show=[this.pageNumId*4-3]
            }
            })
        }
       
      },
      // 点击页码
      page(i){
          this.pageId=i
          if(this.addressId===0){
               axios.post("/api/love/loveList?pageNum="+this.pageId+"&pageSize="+6).then(response=>{
                this.result=response.data.list;
                this.pageNum=response.data.pages
              })
          }else{
              axios.post("/api/love/loveListByAddressId?addressId="+this.addressId+"&pageNum="+this.pageId+"&pageSize=6").then(response=>{
              this.result=response.data.list;
              this.pageNum=response.data.pages;
            })
          } 
      },

      getAddressList(){
          axios.post("/api/address/addressList").then(response=>{
            
           this.treeData=response.data; 
              
          for(var i=0;i<this.treeData.length;i++){
         
            const newChild = {
              id:this.treeData[i].addressId,
              label:this.treeData[i].addressName,
              parent:0,
              // Image:require('./assets/tourism.jpg'),
              children:[{
                id:this.treeData[i].addressId+'_1',
                label:'侯涛'
              },
              {
                id:this.treeData[i].addressId+'_2',
                label:'霞霞'
              },
              {
                id:this.treeData[i].addressId+'_3',
                label:'合照',
              },
              {
                id:this.treeData[i].addressId+'_0',
                label:'风景',
              }
              ]
             }
              this.treeOrder.push(newChild);
              
          }


        })
      },
    // onchangemd(e){
      
		// console.log(e.target.files)//这个就是选中文件信息
    // const files2 = this.$refs.fileInput.files[0];
    // if (files2){
      
    //   const fileType=files2.type;
    //   if(fileType==='image/jpeg' || fileType==='image/png'){
    //     let formdata = new FormData()
    //     Array.from(e.target.files).map(item => { 
    //         console.log(item)
    //         formdata.append("file", item)  //将每一个文件图片都加进formdata
    //     })
    //     axios.post("/api/love/upload", formdata).then(response=>{
    //        this.result=response.data;     
    //       for(var i=0;i<this.result.length;i++){
    //         // var itm=this.result[i];
    //         // alert(itm.imageName);
    //       }


    //     })}
    //   else{
    //     alert('只允许上传jpg和png类型的文件')
        
    //   }
    // }
		

    // },
       getLoveList(){
        
        axios.post("/api/love/loveList?pageNum="+this.pageNumId+"&pageSize="+6).then(response=>{
          this.result=response.data.list;
          this.pageNum=response.data.pages;
          if(this.pageNum>4){
              this.pageMore="("+this.start+"/"+Math.ceil(this.pageNum/4)+")下一页"
          }else{
              this.pageMore="("+this.start+"/"+this.start+")下一页"
              this.show=response.data.navigatepageNums;
          }

        })
      },
      handleNodeClick(treeOrder){
        this.pageNumId=1;
        this.start=1;
        this.addressId=treeOrder.id;
        axios.post("/api/love/loveListByAddressId?addressId="+treeOrder.id+"&pageNum=1&pageSize=6").then(response=>{
            this.result=response.data.list;
            this.pageNum=response.data.pages;
            if(this.pageNum>4){
              this.pageMore="("+this.start+"/"+Math.ceil(this.pageNum/4)+")下一页"
              this.show=[1,2,3,4]
            }else{
              this.pageMore="("+this.start+"/"+this.start+")下一页"
              this.show=response.data.navigatepageNums;
            }
            
        })
      },
    toBIgChange(liId) {
      this.showModal= true;
      // alert(liId);
      this.imageUrl=liId;
      // alert(this.imageUrl);
      // this.number=this.number+1+this.multiples;
      // document.getElementById(liId).style="transform:scale("+this.number+")"
   
     },
     // 缩小
     toSmallChange(liId) {
      // this.number=0;
      document.getElementById(liId).style="transform:scale(1)"
     },

   }
}
</script>
<style>
	#box ul{
		display: flex;
		flex-wrap: wrap;
        position: absolute;
        top: 0px;
	}
#box li{
		padding: 0px;
    top: 0px;
		list-style: none;
		margin-right: 15px;
}
#box img{
    padding: 3px;
    left: 3px;
    top: 3px;
		list-style: none;
		width: 100px;
		height: 100px;
}
.image{
		list-style: none;
		width: 300px;
		height: 400px;
}
#page{
  display: flex;
		flex-wrap: wrap;
        position: absolute;
        top: 500px;
        left: 40px;
}
.el-tree-node__label {
width: auto;
left: 2px;
margin-top: 5px;
float: left;
font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
font-size: 10px;
color: #999999;
line-height: 10px;
letter-spacing: 1px;

}
#all{
  background-position: center;
	background-repeat: no-repeat;
  background: url('../assets/tourism.jpg') repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position:absolute;
  background-attachment:fixed;  
  background-size: cover;  
  top: 70px;  
  left: 0; 
  touch-action: none;
  

}
 .box2 .ant-modal-body {
   width: 100px;
   overflow-x: auto;
   height: 100px;
   overflow-y: auto;
 }
 .mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.pop {
  background-color: #fff;
 text-align: center;
  position: fixed;
  top: 100px;
  left: 30px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  /* width: 300px;
  height: 400px; */
  z-index: 2
}

</style>
