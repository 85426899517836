const userName = '欢迎';
const userImage = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png';
const falgAdmin = 0;
const flag=0;
const count=0;
const openId="";

export default {
    userName,
    userImage,
    falgAdmin,
    flag,
    openId,
    count
}
