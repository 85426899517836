<template>
  <div class="love_image">
    <!-- <img alt="Vue logo" src="../assets/loveImage.gif" id="welcome_icon"> -->
    <Image msg=""/>

  </div>

  



</template>
<script>
  import Image from '@/components/Image.vue'

  export default {
  name: 'ImageView',
  components: {
    Image
  }
}
</script>

<style scoped>
#love img{
		width: 200px;
		height: 150px;
}
#welcome_icon{
    height: 20px;
    width: 50px;
}
</style>