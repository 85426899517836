import { createRouter, createWebHashHistory } from 'vue-router'
import Love from '../views/Love.vue'
import  Image from '../views/Image.vue'
import other from '../views/other.vue'





const routes = [
  {
    path: '/love_other',
    name: 'love_other',
    component: other,
    meta:{
      icon:'love_other_icon'
    }
  },
  {
    path: '/',
    name: 'love_home',
    component: Love,
    meta:{
      icon:'love_home_icon'
    }
  },
  {
    path: '/love_image',
    name: 'love_image',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    component: Image,
    meta:{
      icon:'love_image_icon'
    }
    

  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
