import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
/* 引入 ElementPlus */
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import vueQr from 'vue-qr'


// import { Viewer } from 'v-viewer'
// import 'viewerjs/dist/viewer.css'


// import Vue from 'vue'
// Vue.prototype.$result
// import axios from 'axios'

// import axios from 'axios'
// import ElementPlus from 'element-plus' .use(ElementPlus)


// createApp.rototype.HOST='/api'
// mock服务器
// axios.defaults.baseURL ='/love';


createApp(App).use(store).use(router).use(ElementPlus).use(vueQr).mount('#app')



  