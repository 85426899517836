<template>
  <div class="love_home" id="love">
      
     <div  id="div1">
        <img
     
       @click="toBIgChange(1)"
        @mouseleave="toSmallChange"
        alt="Vue logo" 
        src="../assets/logo.png" 
        class="img"
        id="logoId"
        :style="{transform:'scale('+multiples+')'}"
      
        >
        <div id="divmessage">
          <div id="message" style="font-family:STFangsong" :hidden="this.xinxi">
            个人信息
          </div>
          <div id="div2">
              <div id="xiaxia">
                <input  type="button" ref="xiaxiaRef"  value="霞霞" @click="xiaxiaMessage(1)" :hidden="this.input1">
              </div>
              <div id="houtao">
                <input type="button"  ref="houtaoRef" value="涛涛" @click="xiaxiaMessage(2)" :hidden="this.input1">
              </div>
          </div>
        </div>
        <div id="mapDiv">
          <div id="mapId" style="font-family:STFangsong" :hidden="this.map">页面导航</div>
          <div id="xiangceId">
               <input type="button" value="相册" @click="imgae()" :hidden="this.input1">
          </div>
          <div id="addId"> 
            <!-- :hidden="this.input2"  :hidden="this.input2"-->
              <input id="inputId" type="button"  value="添加" @click="addImage()" :hidden="this.input2" >
          </div>
          <!-- <div id="removeId">
               <input type="button" value="删除" @click="remove()" :hidden="this.input2">
          </div> -->
         
          
        </div>
      <div>
     <!-- 个人信息弹窗 -->
    <div class="mask" v-if="showModal2" @click="showModal2=false"></div>
    <div class="pop" v-if="showModal2">
      <div id="xiaxiaId">
          <div id="userMessage">
            <span id="messageSpan">{{this.messageSpan}}</span>
             
          </div>
      
          <div id="divName">
             <div id="divTitle">
                 姓名:
            </div>
            <div id="divText" > 
              
              <input id="textInput" ref="nameRef" type="text" :value="this.userMessage.name" v-bind:readonly="this.readonly">
            </div>
            <el-divider></el-divider>
          </div>
          <div id="divBirth">
             <div id="divTitle">
                 生日:
            </div>
            <div id="divText" > 
          
              <input id="textInput" ref="birthRef" type="text" :value="this.userMessage.birth" v-bind:readonly="this.readonly">
            </div>
                <el-divider></el-divider>
          </div>
         
         <div id="divAddress">
             <div id="divTitle">
                 地址:
            </div>
            <div id="divText" > 
             
              <input  id="textInput" ref="addressRef" type="text" :value="this.userMessage.address" v-bind:readonly="this.readonly">
            </div>
            <el-divider></el-divider>
          </div>
          
          <div id="divPhone">
             <div id="divTitle">
                 电话:
            </div>
            <div id="divText" > 
            
              <input id="textInput"  ref="phoneRef" type="text" :value="this.userMessage.phone" v-bind:readonly="this.readonly">
            </div>
            <el-divider></el-divider>
          </div>
       
         <div id="divWechat">
             <div id="divTitle">
                 微信:
            </div>
            <div id="divText" > 
          
              <input id="textInput" ref="wechatRef" type="text" :value="this.userMessage.wechat" v-bind:readonly="this.readonly">
            </div>
              <el-divider></el-divider>
          </div>
     
          <div id="divQq">
             <div id="divTitle">
                 QQ:
            </div>
            <div id="divText" > 
            
              <input  id="textInput" ref="qqRef" type="text" :value="this.userMessage.qq" v-bind:readonly="this.readonly">
            </div>
                <el-divider></el-divider>
          </div>
          <div id="divJieshao">
             <div id="divTitle">
                 简介:
            </div>
            <div id="divText" > 
       
              <input id="textInput"  ref="jianjieRef" type="text" :value="this.userMessage.message" v-bind:readonly="this.readonly">
            </div>
                <el-divider></el-divider>
         </div> 
       <div class="demo-image__preview">
        <el-image  id="elImageid" 
          style="float:left;width: 70%; height: 180px"
          
          :src="this.imageLists[this.imageIndex]" 
          :preview-src-list="this.imageLists"
          alt="null">
          
        </el-image>

        <div style="float:right;width: 30%; height: 180px">
          <div id="nextId">
            <input value="上一张" type="button" @click="preImage()"  :hidden="this.change">
            <input value="下一张" type="button" @click="nextImage()" :hidden="this.change">
          </div>
          <div id="deupId">
              


            <input value="删除" type="button" @click="delectImage()" :hidden="this.change">
            <input id="inputId" type="file"  ref="fileInput" @change="onchangemd"  accept=".jpg,.png" :hidden="this.change">
            <div v-if="this.progressHidden" >
                <el-progress :text-inside="true" :stroke-width="22"   :percentage="this.percentageNumber" :status="this.status"></el-progress>
            </div>
          </div>
           
        </div>
        
      </div>
    

         
        <div id="changeid">
           <input value="修改信息" type="button" :hidden="this.change" @click="changeUser" >
        </div>
        
    

      </div>
          
    
    
    
 
 
    </div>
    <!-- 相册添加图片弹窗 -->
    <div class="maskAddImage" v-if="showModal3" @click="showModal3=false"></div>
    <div class="popAddImage" v-if="showModal3">
      <div id="tanchuang">
        <div id="photoId">
         <div id="changeIdPhoto">
              请选择照片类型
         </div>
        <div id="redioId">
          <el-radio-group v-model="radio">
            <el-radio-button label="风景照片"></el-radio-button>
            <el-radio-button label="涛涛照片"></el-radio-button>
            <el-radio-button label="霞霞照片"></el-radio-button>
            <el-radio-button label="两人合照"></el-radio-button>
          </el-radio-group>
        
        </div>

      </div>
    <el-divider></el-divider>
     
    <div id="adddressId">
         <div id="changeIdPhoto">
          请选择地点
          </div>
        <div id="selectId"> 
        <el-select v-model="value" clearable placeholder="请选择">
          <el-option
            v-for="item in this.options"
            :key="item.pid"
            :label="item.provincial"
            :value="item.provincial">
          </el-option>
        </el-select>
      </div>
    </div>

    <el-divider></el-divider>

     <div id="photoId">
      <div id="changeIdPhoto">
          请选择照片(jpg/png)
      </div>
      <div id="inputIdAdd">
        <input id="xxx" type="file"  ref="fileInput" @change="onchangemdAdd"  accept=".jpg,.png">
        <div v-if="this.progressHidden" >
          <el-progress :text-inside="true" :stroke-width="22"   :percentage="this.percentageNumber" :status="this.status"></el-progress>
        </div>
        <!-- <el-upload
          class="upload-demo"
          action="/api/love/addImagePhoto"
          :data="{radio:this.radio,value:this.value,inputValue:this.inputValue}"
          ref="upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          :auto-upload="false"
          :on-success="success">
         
          <el-button  size="small" type="primary">选取文件</el-button>
          
        </el-upload> -->
        </div>
     </div>
      <el-divider></el-divider>
    <div id="photoImageId">
        <div >
            <input type="text" placeholder="图片介绍" v-model="inputValue">
        </div>

    </div>
      <el-divider></el-divider>
     <div id="photoId">
        <el-button type="primary"  style="margin-left: 10px;" size="small" @click="submitUpload">上传到服务器</el-button>
     </div>
        
     
    
      </div>
      
      

        

    </div>
      
     <!-- 中间照片放大弹窗 -->
    <div class="masks" v-if="showModal" @click="showModal=false"></div>
    <Love    msg=""/>
    
    <div class="pops" v-if="showModal">
        <!-- <button @click="showModal=false" class="btn">点击出现弹框</button> -->
         <img
        @mouseleave="toSmallChange"
        alt="Vue logo" 
        src="../assets/logo.png" 
        class="img"
        :id="1"
        :style="{transform:'scale(2)'}"
      
        >
    </div>
  </div>
     </div> 
    
  </div>


 

  

</template>


<script>
import Love from '@/components/Love.vue'
import globPub from '@/assets/global_variable'
import axios from 'axios'
import AppVue from '@/App.vue'



export default {
  
  // components:{
  //   // love
  // },
  data(){
    return{
      inputValue:'',
      fileList:[],
      value:'',
      options:[],
      radio:'风景照片',
      showModal3:false,
      messageSpan:'',
      readonly:false,
      userId:0,
      imageIndex:0,
      change:false,
      showModal2:false,
      map:true,
      xinxi:true,
      input2:true,
      input1:true,
      visible: false,
      multiples: 1,
      showModal: false,
      userMessage:'',
      imageLists:[],
      changeUsrMessage:{
        name:'',
        birth:'',
        phone:'',
        qq:'',
        wechat:'',
        photoid:'',
        address:'',
        message:'',
      },
      formdatas:new FormData(),
      progressHidden:false,
      percentageNumber:1,
      status:'warning',
      
    }
  },
 

  created(){
    var url=window.location.search ;
    if(url!=""){
      if(globPub.flag===1){
        return;
      }
      var param1=url.split("&")[0];
      param1=param1.substring(1,url.length-1).trim();
      var param2=url.split("&")[1];
      param2=param2.trim();
      var param3=url.split("&")[2];
      globPub.openId=param3.trim();
      
      // alert(globPub.openId.split("=")[1]);
      this.thanSuccess(param1,param2);
    }
  },
  mounted(){
    this.dengluIs();
  },
  methods:{
    thanSuccess(param1,param2){
       axios.post("/api/wechat/thanSuccess?userName="+param1.split("=")[1]+"&userImage="+param2.split("=")[1]).then(response=>{
          if(response.data.code===200){
              globPub.falgAdmin=2;
              globPub.userImage=param2.split("=")[1];
              globPub.userName=decodeURIComponent(param1.split("=")[1]);
              globPub.flag=1;
               this.$message({
                message: "欢迎您"+globPub.userName,
                type: 'success'
              });
              AppVue.methods.music();
          }else if(response.data.code===400){
             this.$message({
                message: "错误登录方式",
                type: 'error'
              });
              globPub.falgAdmin=0;
          }
       })
    },
    onchangemdAdd(e){
      // alert(e.target.files)//这个就是选中文件信息
    const files2 = this.$refs.fileInput.files[0];
    if (files2){
      
      const fileType=files2.type;
      if(fileType==='image/jpeg' || fileType==='image/png'){
        let formdata = new FormData()
        Array.from(e.target.files).map(item => { 
            console.log(item)
            formdata.append("file", item)  //将每一个文件图片都加进formdata
        })
        this.formdatas=formdata;
     }else{
        alert('只允许上传jpg和png类型的文件')
        
      }
    }

    },
    // success(response){
    //   alert(response);
    //    this.$message({
    //           message: "修改成功！",
    //           type: 'success'
    //         });
    // },
    //上传照片
     submitUpload() {
      this.progressHidden=true;
        window.timer = setInterval(()=>{
          this.percentageNumber=this.percentageNumber+1
        },2000)
        axios.post("/api/love/addImagePhoto?radio="+this.radio+"&value="+this.value+"&inputValue="+this.inputValue,this.formdatas).then(response=>{
           
            if(response.data.code===200){
              clearInterval(window.timer);
              this.percentageNumber=100;
              this.status="success";
              
              this.$message({
                message: "上传成功！",
                type: 'success'
              });
              setTimeout(() => {
                this.progressHidden=false;
                this.percentageNumber=1;
                this.status="warning";
              }, 1000);
         
            }else{
              this.$message({
                message: "上传失败！",
                type: 'success'
              });
            }
        })
      
     },
    // 添加相册
    addImage(){
      axios.post("/api/provincial/provincialList").then(response=>{
        this.progressHidden=false;
        this.options=response.data;
        if(this.options.length>0){
          this.showModal3=true;
        }
      })
      
    },
    // 修改个人信息
    changeUser(){
      this.changeUsrMessage.name=this.$refs.nameRef.value;
      this.changeUsrMessage.birth=this.$refs.birthRef.value;
      this.changeUsrMessage.qq=this.$refs.qqRef.value;
      this.changeUsrMessage.wechat=this.$refs.wechatRef.value;
      this.changeUsrMessage.address=this.$refs.addressRef.value;
      this.changeUsrMessage.message=this.$refs.jianjieRef.value;
      this.changeUsrMessage.phone=this.$refs.phoneRef.value;
      this.changeUsrMessage.photoid=this.userId;
       axios.post("/api/user/changeUserMessageByPhotoId",this.changeUsrMessage).then(response=>{
          if(response.data!=""){
            this.$message({
              message: "修改成功！",
              type: 'success'
            });

            

            this.xiaxiaMessage(this.userId);
          }else if(response.data===0){
            this.$message({
              message: "其他用户正在修改当前信息，请等待！",
              type: 'warning'
            });
          }

       })

    },
  
  // 个人信息弹窗上传图片
    onchangemd(e){
      this.progressHidden=true;
       window.timer = setInterval(()=>{
          this.percentageNumber=this.percentageNumber+5
       },2000)
    // alert(e.target.files)//这个就是选中文件信息
    const files2 = this.$refs.fileInput.files[0];
    if (files2){
      
      const fileType=files2.type;
      if(fileType==='image/jpeg' || fileType==='image/png'){
        let formdata = new FormData()
        Array.from(e.target.files).map(item => { 
            console.log(item)
            formdata.append("file", item)  //将每一个文件图片都加进formdata
        })
        axios.post("/api/image/upload?imageId="+this.userId, formdata).then(response=>{
           this.result=response.data;     
           if(response.data!=""){
            clearInterval(window.timer);
            this.percentageNumber=100;
            this.status="success";
            this.$message({
              message: "添加成功！",
              type: 'success'
            });
             setTimeout(() => {
                this.progressHidden=false;
                this.percentageNumber=1;
                this.status="warning";
            }, 1000);

            this.xiaxiaMessage(this.userId);
          }


        })}
      else{
        alert('只允许上传jpg和png类型的文件')
        
      }
    }
    },
    // 个人信息弹窗删除照片
    delectImage(){
      
      axios.post("/api/image/deleteByImageAddress?imageAddress="+this.imageLists[this.imageIndex]).then(response=>{
         if(response.data!=""){
            this.$message({
              message: "删除成功！",
              type: 'success'
            });

            this.xiaxiaMessage(this.userId);
          }
      })

    },
    // 个人信息弹窗上一页
    preImage(){
      if(this.imageLists.length===0){
        return;
      }
      if(this.imageIndex===0){
        return;
      }else{
        this.imageIndex=this.imageIndex-1;
      }

    },
    // 个人弹窗下一页
    nextImage(){
      if(this.imageLists.length===0){
        return;
      }
      if(this.imageIndex===this.imageLists.length-1){
        return;
      }else{
        this.imageIndex=this.imageIndex+1;
      }
   
    },
    // 个人信息弹窗展示
    xiaxiaMessage(userId){
        this.userId=userId;
        this.imageIndex=0;
        if(userId===1){
          this.messageSpan=this.$refs.xiaxiaRef.value+"信息";
        }else if(userId===2){
          this.messageSpan=this.$refs.houtaoRef.value+"信息";
        }
        
        window.timer = setInterval(()=>{
        console.log(globPub.falgAdmin);
      if(globPub.falgAdmin===0){
        this.readonly=true;
        this.change=true;
      }else if(globPub.falgAdmin===1){
       this.readonly=true;
       this.change=true;
      }else if(globPub.falgAdmin===2){
        this.readonly=false;
        this.change=false;
      }
    },1)
    
      axios.post("/api/user/userMessage?photoId="+this.userId).then(response=>{
          this.imageLists=[];
          
          for(var i=0;i<response.data.imageList.length;i++){
            this.imageLists.push(response.data.imageList[i].imageaddress)
          }
      
          console.log(this.imageLists);
          this.userMessage=response.data;
          var date=new Date(this.userMessage.birth);
          var y = date.getFullYear();
          var m = date.getMonth() + 1;
          m = m < 10 ? ('0' + m) : m;
          var d = date.getDate();
          d = d < 10 ? ('0' + d) : d;
          this.userMessage.birth=y+"-"+m+"-"+d;

          this.showModal2=true;
        })

    },

// 点击登录
    dengluIs(){
       window.timer = setInterval(()=>{
       console.log(globPub.falgAdmin);
      if(globPub.falgAdmin===0){
        this.input1=true
        this.input2=true;
        this.xinxi=true;
        this.map=true;
      }else if(globPub.falgAdmin===1){
        this.input1=false;
        this.xinxi=false;
         this.map=false;
      }else if(globPub.falgAdmin===2){
        this.xinxi=false;
        this.input2=false;
        this.input1=false;
        this.map=false;
      }
    },1)
      
      
    },
    // 相册
    imgae(){
      this.$router.replace("/love_image")
    },

     handleCancel() {
      this.visible = false;
      this.multiples = 1
     },
    toBIgChange() {
  
      
      this.showModal= true;
      
     },
     // 缩小
     toSmallChange() {
      
       this.multiples = 1;
     },
  },
  name: 'HomeView',
  components: {
    Love
  }
}
</script>
<style >
#love img{
		width: 200px;
		height: 150px;
}
#love{
  background-position: center;
	background-repeat: no-repeat;
  background: url('../assets/tourism.jpg') repeat;
  width: 100%;
  height: 100%;
  /* background: url("~@/images/home/h_bg.png") center center no-repeat; */
  background-size: 100% 100%;
  position:fixed;
  background-attachment:fixed;  
  background-color: rgb(255, 208, 0);
  position: fixed;
  padding: 0px;
  top: 70px;
  left: 0; 
  touch-action:none;
  
}
/* .head-btn-box {
   margin-bottom: 20px;
 } */
 .box .ant-modal-body {
   width: 100px;
   overflow-x: auto;
   height: 100px;
   overflow-y: auto;
 }
.masks {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.maskAddImage {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.pops {
  background-color: #fff;
 
  position: fixed;
  top: 100px;
  left: 100px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
.popAddImage {
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 100px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
.pop {
  background-color: #fff;
 
  position: fixed;
  top: 100px;
  left: 100px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
#div2{
  float: left;
  padding-left: 2%;
  padding-left: 5px;
  padding-top: 1px;
}
#message{
  font-size: 18px;
  color: #34db6c;
  height: 8%;
  background-color: rgb(118, 93, 62);
}
#mapId{
  font-size: 18px;
  color: #34db6c;
  height: 8%;
  background-color: rgb(118, 93, 62);
}
#divmessage{
  float: left;
  padding-left: 2px;
}
#mapDiv{
  float: right;
  padding-right: 30px;
}
#div1{
  /* padding-left: 30%; */
  left: 200px;
  top:90px
}
#houtao{
  padding-top: 20px;
}
#addId{
  padding-top: 20px;
}
#removeId{
  padding-top: 20px;
}

#divName{
  float: left;
  padding-left: 10px;
  width: 100%;
  height: 8%;
  /* padding-top: 50px; */
}

#divAddress{
  padding-left: 10px;
  padding-top: 0px;
  width: 100%;
  height: 8%;
  float: left;
}
#divPhoto{
  padding-left: 10px;
  padding-top: 0px;
  width: 18%;
  height: 8%;
  float: left;
  background-color: rgb(77, 162, 49);
}
#logoId{
  /* float: left; */
  padding-left: 2px;
}
#divPhone{
  padding-left: 10px;
  padding-top: 0px;
  width: 100%;
  height: 8%;
  float: left;
}
#divWechat{
  padding-left: 10px;
  padding-top: 0px;
  width: 100%;
  height: 8%;
  float: left;
}
#divQq{
  padding-left: 10px;
  padding-top: 0px;
  float: left;
  width: 100%;
  height: 8%;
}

#divJieshao{
  padding-left: 10px;
  padding-top: 0px;
  float: left;
  width: 100%;
  height: 8%;
}
#divBirth{
  padding-left: 10px;
  padding-top: 0px;
  float: left;
  width: 100%;
  height: 8%;
}
#divTitle{
  float: left;
  color: #34db6c;
  size: 20px;
}
#divText{
  float: left;
  padding-left: 40px;
}

#userMessage{
  padding-left: 10px;
  padding-top: 0px;
  /* float: left; */
  width: 100%;
  height: 8%;
}
#messageSpan{
  color: #34db6c;
  font-size: 25px;
}
#textInput{
  border: 0px;
  outline:none;
}
#xiaxiaId{
  background-color: #fff;
  background-image: url("../assets/aixin.png");
  position: fixed;
  top: 50px;
  left: 10px;
  width: 380PX;
  height:650PX;
  z-index: 2
}
#tanchuang{
  background-color: #fff;
  background-image: url("../assets/aixin.png");
  position: fixed;
  top: 150px;
  left: 10px;
  width: 380PX;
  height:400PX;
  z-index: 2
  
}


#divTextphoto{
  width: 700px;
  height: 400xp;
  border: 1px solid #ccc;
  margin: 0 auto;
  padding: 0;
}

#elImageid{
  float: left;
  padding-left: 50px;
 
}
#deupId{
  padding-top: 70px;
}
#nextId{
  padding-top: 20px;
}
#changeid{
  float: left;
  padding-top: 20px;
  padding-left: 100px;
}
#photoText{
  float: left;
  color: #2bc141;
  padding-left: 40px;
}

#photoId{
  width: 100%;
  height: 10%;
}
#adddressId{
  width: 100%;
  height: 8%;
}
#photoImageId{
  width: 100%;
  height: 4%;
}

#changeIdPhoto{
  font-size: 20px;
  color: #020a04;
}
#inputIdAdd{
  float: left;
  padding-left: 70px;
}


</style>
