<template>
    <div>
      <!-- <input id="inputId" type="file" ref="fileInput" @change="onchangemd" accept=".jpg,.png"> -->
    </div>
    <div id="divId">
      <div id="divId2">
        <div id="divId3">
          {{this.timeNow}}
        </div>
        <div id="divSpeak" v-if="this.pingLunDiv" v-infinite-scroll="load" infinite-scroll-distance="10" style="overflow:auto"  >
          <div>
             <img  id="headerImage2" :src="this.heraderImage" ><input type="text" v-model="this.inputValue" placeholder="请文明用语"><input type="button" @click="liuyan" value="评论">
          </div>
          <!-- infinite-scroll-disabled="disabled" -->
          <div id="liuyanDiv">
               <ul class="infinite-list"    v-for="i in this.pingLunList" :key="i.parentid" >
                <div    class="infinite-list-item" >
                  <div id="timeDiv" > 
                    <div style="float:left">
                        <img  id="headerImage" :src="i.userheader" >
                    </div>
                    <div  style="float:right;width:70%"> 
                      <div id="userNameDiv">
                        <span>
                            <span >{{i.speak}}</span>
                         <span id="timeSpan">
                          <el-badge :value="i.pinglunsonList.length" class="item" >
                            <el-button size="small" id="item_button" @click="askListByParentId(i.parentid,i.userheader,i.username)"> 💬回复</el-button>
                          </el-badge>
                              <!-- <a style="text-decoration:none" href="#" @click="askDIv=i.parentid">   💬回复</a> -->
                              
                             <a style="text-decoration:none" href="#" @click="clickAdd(i.parentid)">    {{"   👍"+i.click}}</a>
                          
                            <!-- <span>+1</span> -->
                            </span>                          
                        </span>   
                      </div>                       
                        <div>                           
                        </div>           
                    </div> 
                    </div>     
                </div> 
                <div id="speakDiv">
                  <div>
                      <a  href="#"  style="text-decoration:none"  @click="userMessagePinLun(i.openid)" id="speakSpan">
                      {{i.username}}
                  </a>
                  </div>
                   <div>
                      <span id="timeSpan">
                      {{new Date(i.time).toLocaleDateString()}}
                     </span>
                  </div>
                  <!-- <div id="askDiv" v-if="askDIv===i.parentid">
                    <span>
                       <input :v-model="asckPingLun" style="width:200px" placeholder="输入回复" type="text">
                       <input type="button" @click="asckLiuyan" style="width:40px" value="确认" >
                    </span>
                   
                    
                  </div> -->
                
                  
                </div>
                <el-divider></el-divider>
              </ul>
              <div id="emptyLiuYanDiv">
                   <span id="emptyLiuYan" v-if="empty">暂无留言</span>
              </div>
             
          </div>
        
        </div>
      </div>
      
    </div>
    <div class="mask" v-if="showModal" @click="showModal=false"></div>
    <div class="pop" v-if="showModal">
         <div id="divSpeak2" v-if="this.pingLunDiv" v-infinite-scroll="load" infinite-scroll-distance="10" style="overflow:auto"  >
          <div>
             <img  id="headerImage2" :src="this.heraderImage" ><span >查询指定用户的所有评论</span>
          </div>
          <!-- infinite-scroll-disabled="disabled" -->
          <div id="liuyanDiv">
               <ul class="infinite-list2"    v-for="i in this.pingLunListByOpenId" :key="i.parentid" >
                <div    class="infinite-list-item2" >
                  <div id="timeDiv" > 
                    <div style="float:left">
                        <img  id="headerImage" :src="i.userheader" >
                    </div>
                    <div  style="float:right;width:60%"> 
                      <div id="userNameDiv">
                        <span>
                            <span >{{i.speak}}</span>
                         <span id="timeSpan">
                               <!-- <span>   💬回复</span> -->
                             <span >    {{"   👍"+i.click}}</span>
                          
                            <!-- <span>+1</span> -->
                            </span>                          
                        </span>   
                      </div>                       
                        <div>                           
                        </div>           
                    </div> 
                    </div>     
                </div> 
                <div id="speakDiv">
                  <div>
                      <span  id="speakSpan">
                      {{i.username}}
                  </span>
                  </div>
                   <div>
                      <span id="timeSpan">
                      {{i.time}}
                  </span>
                  </div>
                
                  
                </div>
                <el-divider></el-divider>
              </ul>
          </div>
        
        </div>
    </div>

    <div class="masks" v-if="showModal2" @click="showModal2=false"></div>
    <div class="pops" v-if="showModal2">
         <div id="divSpeak2" v-if="this.pingLunDiv" v-infinite-scroll="load" infinite-scroll-distance="10" style="overflow:auto"  >
          <div>
             <img  id="headerImage2" :src="this.heraderImage" ><input type="text" :placeholder="this.placeholder" v-model="askValue"><input type="button" @click="huifu" value="回复">
          </div>
          <!-- infinite-scroll-disabled="disabled" -->
          <div id="liuyanDiv">
               <ul class="infinite-list3"    v-for="i in this.pingLunSonList" :key="i.parentid" >
                <div    class="infinite-list-item3" >
                    <img id="headerImage" :src="i.header1">
                    <a href="#" @click="userClick(i.user1,i.header1)">{{i.user1+"   "}}</a>
                    <span>回复</span>
                    <img id="headerImage" :src="i.header2">
                    <a href="#" @click="userClick(i.user2,i.header2)">{{i.user2+"   "}}</a>
                    <span>{{i.speak+"   "}}</span>
                    <span>{{i.time}}</span>
                  
                </div>
                <el-divider></el-divider>
              </ul>
               <div id="emptyLiuYanDiv">
                   <span id="emptyLiuYan" v-if="empty2">暂无回复</span>
              </div>
          </div>
        
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import global from '../assets/global_variable'


export default {
 
  name: 'Love_Home',
  props: {
    msg: String
  },

  data(){
    return{
      parentidIs:'',
      empty2:false,
      empty:false,
      parentId:'',
      pinglunClick:0,
      userImage:'',
      userName:'',
      askValue:'',
      placeholder:'点击昵称进行回复',
      pingLunSonList:[],
      showModal2:false,
      showModal:false,
      heraderImage:'',
      pingLun:{
        username:'',
        userheader:'',
        time:new Date(),
        speak:'',
        openid:'',
        click:0,
      },
      pingLunSon:{
        parentid:'',
        isread:0,
        user1:'',
        user2:'',
        header1:'',
        header2:'',
        speak:'',
        time:new Date(),
      },
      inputValue:'',
      pingLunDiv:false,
      count:8,
      timeNow:'',
      myTimeDisplay:'',
      pingLunList:[],
      pingLunListByOpenId:[]
      
        
    }
  },

created(){
  this.getPingLunList();
},
  mounted(){
    this.dengluIs();
    this.getTime()
    clearInterval(this.myTimeDisplay);
    this. myTimeDisplay = setInterval(() => {
      this.getTime(); //每秒更新一次时间
    }, 1000);
  },

  methods:{
    huifu(){

        this.pingLunSon.parentid=this.parentId;
        this.pingLunSon.speak=this.askValue;
        this.pingLunSon.user1=global.userName;
        this.pingLunSon.user2=this.userName;
        this.pingLunSon.header1=global.userImage;
        this.pingLunSon.header2=this.userImage;
        if(this.askValue===""){
           this.$message({
                message: '评论不能为空',
                type: 'warning'
              }); 
              return;
        }
        axios.post("/api/pingLunSon/addPingLun",this.pingLunSon).then(response=>{
          if(response.data.code===200){
            this.askValue="";
            this.placeholder="点击昵称进行回复";
            this.getPingLunList();
            this.pingLunListHuiFu();
              this.$message({
                message: '回复成功',
                type: 'success'
              });
          }
        })
        // this.parentId="";
        // this.inputValue="";
        // this.userName="";
        // this.userImage="";
        // this.pinglunClick=0;

    },
    pingLunListHuiFu(){
      if(this.showModal2===false){
          clearInterval(window.timer);
          return;
      }
      axios.post("/api/pingLunSon/askListByParentId?parentId="+this.parentId).then(response=>{
            this.pingLunSonList=response.data;
            if(this.pingLunSonList.length===0 || response.data===null){
              this.empty2=true;
            }else{
              this.empty2=false;
            }
             for(var i=0;i<this.pingLunSonList.length;i++){
               var date=new Date(this.pingLunSonList[i].time);
               var y = date.getFullYear();
               var m = date.getMonth() + 1;
               m = m < 10 ? ('0' + m) : m;
               var d = date.getDate();
               d = d < 10 ? ('0' + d) : d;
               var h=date.getHours();
               h = h < 10 ? ('0' + h) : h;
               var m2=date.getMinutes();
               m2 = m2 < 10 ? ('0' + m2) : m2;
               var mini=date.getSeconds();
               mini = mini < 10 ? ('0' + mini) : mini;
               this.pingLunSonList[i].time=y+"-"+m+"-"+d+" "+h+":"+m2+":"+mini;
            }

        })
    },
    userClick(user,herader){
      this.pinglunClick=1;
      this.placeholder="请输入回复"+user+"的内容";
      this.userName=user;
      this.userImage=herader;
    },
    updatePingLunSonIsRead(parentid){
        axios.post("/api/pingLunSon/updatePingLunSonIsRead?parentId="+parentid).then(response=>{
              if(response.data.code===400){
                 alert(response.data.data)
              }else{
                this.getPingLunList();
              }
        })
    },
    askListByParentId(parentid,userheader,user){ 
      // if(this.show2===false){
      //     clearInterval(window.timer);
      //     return;
      // }
      if(this.parentidIs!=parentid){
          this.pingLunSonList=[]
          this.empty2=false;
          this.parentidIs=parentid;
      }    
      this.updatePingLunSonIsRead(parentid);
      this.userName=user;
      this.userImage=userheader;
      this.showModal2=true;
      this.parentId=parentid;
      this.askValue="";

        axios.post("/api/pingLunSon/askListByParentId?parentId="+parentid).then(response=>{
            this.pingLunSonList=response.data;
            if(this.pingLunSonList.length===0|| response.data===null){
              this.empty2=true;
              
            }else{
              this.empty2=false;
            }
             for(var i=0;i<this.pingLunSonList.length;i++){
               var date=new Date(this.pingLunSonList[i].time);
               var y = date.getFullYear();
               var m = date.getMonth() + 1;
               m = m < 10 ? ('0' + m) : m;
               var d = date.getDate();
               d = d < 10 ? ('0' + d) : d;
               var h=date.getHours();
               h = h < 10 ? ('0' + h) : h;
               var m2=date.getMinutes();
               m2 = m2 < 10 ? ('0' + m2) : m2;
               var mini=date.getSeconds();
               mini = mini < 10 ? ('0' + mini) : mini;
               this.pingLunSonList[i].time=y+"-"+m+"-"+d+" "+h+":"+m2+":"+mini;
            }
             window.timer = setInterval(()=>{
                this.pingLunListHuiFu();
              },4000)

        })
    },
    // asckLiuyan(){
    //   this.askDIv="";
    // },
    clickAdd(parentid){
        axios.post("/api/pinglun/clickAdd?parentId="+parentid).then(response=>{
            if(response.data.code===200){
                this.getPingLunList();
            }
        })
    },
    userMessagePinLun(openid){
      if(openid==="访客"){
        this.$message({
          message: '该用户为访客登录，查不到该用户信息',
          type: 'warning'
        });
         this.showModal=false;
      }else{
         axios.post("/api/pinglun/selectByOpenId?openId="+openid).then(response=>{
              this.showModal=true;
              this.pingLunListByOpenId=response.data
              for(var i=0;i<this.pingLunListByOpenId.length;i++){
               var date=new Date(this.pingLunListByOpenId[i].time);
               var y = date.getFullYear();
               var m = date.getMonth() + 1;
               m = m < 10 ? ('0' + m) : m;
               var d = date.getDate();
               d = d < 10 ? ('0' + d) : d;
               var h=date.getHours();
               h = h < 10 ? ('0' + h) : h;
               var m2=date.getMinutes();
               m2 = m2 < 10 ? ('0' + m2) : m2;
               var mini=date.getSeconds();
               mini = mini < 10 ? ('0' + mini) : mini;
               this.pingLunListByOpenId[i].time=y+"-"+m+"-"+d+" "+h+":"+m2+":"+mini;
            }
         })
      }
     
      // alert("userName")
    },
    liuyan(){
      this.pingLun.userheader=global.userImage;
      this.pingLun.username=global.userName;
      this.pingLun.speak=this.inputValue;

      if(this.inputValue===""){
        this.$message({
                message:"评论不能为空",
                type: 'warning'
        });
        return;
      }
      
      
      if(global.openId===""){

       this.pingLun.openid="访客";
      }else{
  
        this.pingLun.openid=global.openId;
      }
     
       axios.post("/api/pinglun/addPingLun",this.pingLun).then(response=>{
          this.getPingLunList(); 
          if(response.data.code===200){
            this.inputValue='';
            this.$message({
                message: global.userName+"：评论成功",
                type: 'success'
              });
          }
       })

    },
      dengluIs(){
       window.timer = setInterval(()=>{
       console.log(global.falgAdmin);
       this.heraderImage=global.userImage;
      if(global.falgAdmin===0){
        
        this.pingLunDiv=false;
      }else if(global.falgAdmin===1){
         this.pingLunDiv=true;
      }else if(global.falgAdmin===2){
         this.pingLunDiv=true;
      }
    },1)},
     load () {
        if(this.pingLunList.length-1<this.count){
          this.count += 1
        }
        
    },
    getPingLunList(){
        axios.post("/api/pinglun/pingLunList").then(response=>{
            this.pingLunList=response.data;
            
            this.count=this.pingLunList.length;
            if(this.count===0){
              this.empty=true;
              
            }else{
              this.empty=false;
            }
            
            // for(var i=0;i<this.pingLunList.length;i++){
            //    var date=new Date(this.pingLunList[i].time);
            //    alert(this.pingLunList[i].time);
            //    var y = date.getFullYear();
            //    var m = date.getMonth() + 1;
            //    m = m < 10 ? ('0' + m) : m;
            //    var d = date.getDate();
            //    d = d < 10 ? ('0' + d) : d;
            //    var h=date.getHours();
            //    h = h < 10 ? ('0' + h) : h;
            //    var m2=date.getMinutes();
            //    m2 = m2 < 10 ? ('0' + m2) : m2;
            //    var mini=date.getSeconds();
            //    mini = mini < 10 ? ('0' + mini) : mini;
            //    this.pingLunList[i].time=y+"-"+m+"-"+d+" "+h+":"+m2+":"+mini;
            // }
            
        })
    },
    getTime(){
      //  var _this = this;
       //date1结束时间
        let date1 = new Date();
        //date2当前时间
        let date2 = new Date("2019-09-08");
        date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
        const diffDate = diff / (24 * 60 * 60 * 1000);  //计算当前时间与结束时间之间相差天数
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        this.timeNow = '侯涛和霞霞在一起：'+diffDate+'天'+hh+'时'+mf+'分'+ss+'秒';
        // console.log(this.timeNow);
    },
     onchangemd(e){
      
		console.log(e.target.files)//这个就是选中文件信息
    const files2 = this.$refs.fileInput.files[0];
    if (files2){
      
      const fileType=files2.type;
      if(fileType==='image/jpeg' || fileType==='image/png'){
        let formdata = new FormData()
        Array.from(e.target.files).map(item => { 
            console.log(item)
            formdata.append("file", item)  //将每一个文件图片都加进formdata
        })
        axios.post("/api/love/upload", formdata).then(response=>{
           this.result=response.data;     
          for(var i=0;i<this.result.length;i++){
            // var itm=this.result[i];
            // alert(itm.imageName);
          }


        })}
      else{
        alert('只允许上传jpg和png类型的文件')
        
      }
    }
		

    },
  }
}
</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#divId3{
  //  background-color: #d93533;
   font-size: 18px;
   color: #282826;
   margin-top: 40px;
   height: 25px;
   width: 350px;
   margin-left: 20px;
 
}
#divId{
  background-position: center;
	background-repeat: no-repeat;
  background: url('../assets/tourism.jpg') repeat;
  width: 100%;
  height: 470px;
  // background-size: 500px 500px;
  position:fixed;
  background-attachment:fixed;  
  // background-size: cover;  
}
#divId2{
  background-position: center;
	background-repeat: no-repeat;
  background: url('../assets/aixin.png') repeat;
  width: 100%;
  height: 50%;
  background-size:404px 227px;
  position:absolute;
  background-attachment:fixed;  
  // background-size: cover;  
}
#divSpeak{
  //  width: 100%; 
  // height: 50px;
  background-color: rgb(234, 243, 245); 
  // border: none;
   font-size: 18px;
   color: #282826;
   margin-top: 170px;
   height: 290px;
   width: 400px;
   margin-left: 0px;
   border:1px solid #000;
   
}
#divSpeak2{
  //  width: 100%; 
  // height: 50px;
  background-color: rgb(234, 243, 245); 
  // border: none;
   font-size: 18px;
   color: #282826;
   margin-top: 100px;
   height: 500px;
   width: 400px;
   margin-left: 0px;
   border:1px solid #000;
   
}
#item_button{
  background-color: rgb(234, 243, 245); 
}
#headerImage{  
  width: 40px;
  height: 40px;
}
#headerImage2{
  width: 30px;
  height: 30px;
}
#timeSpan{
  font-size: 13px;
}
#liuyanDiv{
  float: left;
  padding-left: 10px;
  width: 100%;
  height: 270px
}
#timeDiv{
  padding-left: 20px;
}
#userNameDiv{
  padding-right: 70px;
}
#speakDiv{
   height: 2%;
   width: 30%;
   padding-left: 20px;
  //  background-color: #df0d0d;
}
#speakSpan{
  font-size: 15px;
  // background-color: #000;
}
.masks {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.pop {
  background-color: #fff;
 
  position: fixed;
  top: 60px;
  left: 1px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
.pops {
  background-color: #fff;
 
  position: fixed;
  top: 60px;
  left: 1px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
#askDiv{
  padding-left: 180px;
}
#emptyLiuYan{
  font-size: 40px;
  top: 50px;
}
#emptyLiuYanDiv{
  padding-top: 100px;
}
</style>
