<template>
  

  <div class="hello">
    <!-- <button @click="routLove" value="image">love_image</button> -->
    <h1>{{ msg }}</h1>
    <!-- <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p> -->
    <!-- <h3>Installed CLI Plugins</h3> -->
    <!-- <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-router" target="_blank" rel="noopener">router</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-vuex" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
      <li><a href="http://localhost:9090/love/Image2/13" target="_blank" rel="noopener">love</a></li> 
    </ul> -->
    <!-- <h3>Essential Links</h3> -->
    <!-- <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul> -->
    <!-- <h3>Ecosystem</h3> -->
    <!-- <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul> -->
  </div>

  <!-- <el-tree -->
  <!-- class="el-tree-node__label" -->
      <!-- :data="treeOrder" -->
      <!-- :props="defaultProps" -->
      <!-- accordion -->
      
      
      
  
  <!-- </el-tree> -->
    <!-- @node-click="handleNodeClick" -->
</template>

<script>
// import axios from 'axios'
export default {
  name: 'Love_Other',
  props: {
    msg: String
  },
  data(){
    return{
        
    }
  },
  // mounted(){
  //     this.getAddressList()
  // },
  methods:{
      // handleNodeClick(treeOrder) {
      //   alert(treeOrder.label)  
      //    const newChild = {
      //     label:'1级4',
      //     children:[{
      //       label:'1级4-1'
      //     }]
      //    }   
      //    this.treeOrder.push(newChild);

      // },
      // getAddressList(){
      //     axios.post("/api/address/addressList").then(response=>{
            
      //      this.treeData=response.data; 
              
      //     for(var i=0;i<this.treeData.length;i++){
         
      //       const newChild = {
      //         id:this.treeData[i].addressId,
      //         label:this.treeData[i].addressName,
      //         children:[{
      //           id:this.treeData[i].addressId+'1',
      //           label:'侯涛'
      //         },
      //         {
      //           id:this.treeData[i].addressId+'2',
      //           label:'赵秋霞'
      //         },
      //         {
      //           id:this.treeData[i].addressId+'3',
      //           label:'风景',
      //         }
      //         ]
      //        }
      //         this.treeOrder.push(newChild);
      //     }


      //   })
      // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#welcome_icon{
    height: 20px;
    width: 50px;
}
// .el-tree-node__label {

// margin-top: 250px;
// float: left;
// font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
// font-size: 20px;
// color: #999999;
// line-height: 500px;
// letter-spacing: 20px;
// }
</style>
