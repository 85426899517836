<template>
  <div class="love_other" id="other">
    <!-- <img alt="Vue logo" src="../assets/loveImage.gif" id="wlecome_icon"> -->
    <other msg="Welcome to the page, please click welcome"/>
    
  </div>
</template>

<script>
// @ is an alias to /src
import other from '@/components/other.vue'

export default {
  name: 'otherView',
  components: {
    other
  }
}
</script>
<style >
#other img{
		width: 200px;
		height: 150px;
}

#other{
  background-position: center;
	background-repeat: no-repeat;
  background: url('../assets/tourism.jpg') repeat;
  width: 100%;
  height: 100%;
  /* background: url("~@/images/home/h_bg.png") center center no-repeat; */
  background-size: 100% 100%;
  position:absolute;
  background-attachment:fixed;  
  background-size: cover; 
  
}
/* #welcome_icon{
    height: 20px;
    width: 50px;
} */
</style>
